import {Container, Nav, Navbar} from "react-bootstrap";

function NavBar ({context, setContext}: {context: string, setContext: any}) {
  // @ts-ignore

  return (
      <Navbar bg="dark" className="" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => setContext('')}>
            FoodyTek
          </Navbar.Brand>
          <Nav
              activeKey={context}
              onSelect={setContext}
          >
            <Nav.Item>
              <Nav.Link eventKey="auth">Auth</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="device">Device</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="payment">Payment</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="reports/v2">Reports V2</Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
  )
}

export default NavBar
