import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"
import React from "react";
import NavBar from "./NavBar";

import 'bootstrap/dist/css/bootstrap.min.css'

const isDev = window.location.hostname.includes('.dev.')

function App() {
  const queryParams = getQueryParams()
  const token = localStorage.getItem('token') || ''
  const [context, setContext] = React.useState(queryParams.context || '')

  initAuth(queryParams, token)

  return (
      <div>
        <NavBar context={context} setContext={setContext}/>

        {
          isContextValid(context) ?
              // @ts-ignore
              <SwaggerUI url={getUrlFromContext(context)}
                         requestInterceptor={getRequestInterceptor(token)}
                         responseInterceptor={getResponseInterceptor()}
                         docExpansion="none"
              /> :
              <div>
                <h3>Please, select an Api to show docs.</h3>
              </div>
        }

      </div>
  );
}

function getUrlFromContext(context: string) {
  const devStr = isDev ? '.dev' : ''
  const nonApiContexts = ['auth', 'device', 'payment']

  if (nonApiContexts.includes(context)) {
    return `https://${context}${devStr}.foodytek.com/v3/api-docs`
  } else {
    return `https://api${devStr}.foodytek.com/${context}/v3/api-docs`
  }
}
function isContextValid(context: string): boolean {
  const valid = ['auth', 'device', 'payment', 'reports/v2']
  return valid.includes(context)
}

function getRequestInterceptor(token: string): (req: any) => any {
  return (req: any): any => {
    req.headers.Authorization = 'Bearer ' + token
    return req
  }
}

function getResponseInterceptor(): (req: any) => any {
  return (res: Response): Response => {
    if (res.status === 401 || res.status === 403) redirectToLogin()
    if (res.status !== 200) console.log(res)
    return res
  }
}



function initAuth(queryParams: any, token: string) {
  if (token && !queryParams.token) return;

  if (queryParams.token) {
    localStorage.setItem('token', queryParams.token)
    // @ts-ignore
    window.location = window.location.origin
    return;
  }

  redirectToLogin()
}

function redirectToLogin() {
  let redirectTo = window.location.hostname === 'localhost'
      ? 'http://localhost:8080'
      : `https://login${isDev ? '.dev' : ''}.foodytek.com`

  redirectTo += '/login?to=' + encodeURIComponent(window.location.origin + '/')

  // @ts-ignore
  window.location = redirectTo
}

function getQueryParams() {
  let urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

export default App;
